
import startObserverLoading from '@/mixins/startObserverLoading'
import { useMarketStore } from '~/stores/market'

export default {
  name: 'MPRecommendedSlider404404',
  mixins: [startObserverLoading],
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  data() {
    return {
      keenSliderOptions: {
        breakpoints: {
          '(min-width: 768px)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(min-width: 1024px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(min-width: 1280px)': {
            slides: { perView: 5, spacing: 8 },
          },
        },
        slides: { perView: 2, spacing: 8 },
      },
      loadOnce: false,
    }
  },
  fetchOnServer: false,
  async mounted() {
    await this.marketStore.mainPageBestsellers()
  },
}
